<template>
    <Head>
        <title v-if="website.variant.product!==undefined">{{website.variant.product.name ?? ''}}</title>
        <meta v-if="website.variant.descriptions && website.variant.descriptions[0]" name="description"
              :content="website.variant.descriptions[0].text">

        <link rel="canonical" :href="route('search.page') + '/'+website.variant.canonical"/>

        <meta property="og:site_name" :content="website.name"/>
        <meta property="og:title" :content="website.variant.product.name"/>
        <meta v-if="website.variant && website.variant.descriptions && website.variant.descriptions[0]" property="og:description" :content="website.variant.descriptions[0].text"/>
        <meta property="og:image:secure_url" v-if="website.variant.first_picture" :content="website.variant.first_picture.PictureUrlFull"/>
        <meta property="og:type" content="website"/>
        <meta property="og:url" :content="route('search.page') + '/'+website.variant.canonical"/>
        <meta property="og:locale" content="nl"/>
    </Head>

    <Header :menu_items="menu_items" :page="page" :editable="editable" :website="website"/>

    <section class="text-gray-600 body-font section-text" itemscope itemtype="https://schema.org/Product">
        <div class="bg-white  dark:bg-black dark:text-white">
            <div>
                <main class="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8">
                    <div class="flex items-baseline justify-between border-b border-gray-200 pt-6 pb-3">
                        <div v-if="website.variant!==undefined">

                            <nav class="flex" aria-label="Breadcrumb">
                                <button @click.passive="back" v-if="backExists"
                                        class=" text-sm font-medium text-gray-700 hover:text-gray-900 border-gray-300 border rounded p-1.5 pl-2 pr-3 mr-2 float-left">
                                    <span class="sr-only">Terug naar overzicht</span>
                                    <span class="whitespace-nowrap text-xs sm:text-sm  dark:text-gray-200 dark:hover:text-white flex">
                                             <CustomSvg :name="'chevron-left'" color="blue" customClass="w-3 mt-0.5  mr-2 dark:fill-gray-300"></CustomSvg>
                                        <div class="mt-0.5">Terug</div>
                                        </span>
                                </button>
                                <ol class="inline-flex items-center space-x-1 md:space-x-3 ml-3" vocab="https://schema.org/" typeof="BreadcrumbList">
                                    <li class="inline-flex items-center">
                                        <jet-nav-link href="/" title="Home"
                                                      class="inline-flex items-center text-sm font-medium text-gray-700 hover:text-gray-900 dark:text-gray-400 dark:hover:text-white">
                                            <svg class="w-4 h-4 mr-2" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"></path>
                                            </svg>
                                        </jet-nav-link>
                                    </li>

                                    <li v-for="(product_cats,full_url) in website.variant.bread_crumbs" property="itemListElement" typeof="ListItem">

                                        <div class="flex items-center">
                                            <svg class="w-auto h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                                <path fill-rule="evenodd"
                                                      d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                                                      clip-rule="evenodd"></path>
                                            </svg>

                                            <jet-nav-link :href="(route('search.page')+'/'+full_url)" property="item" typeof="WebPage"
                                                          class="ml-1 text-sm font-medium text-gray-700 hover:text-gray-900 md:ml-2 dark:text-gray-400 dark:hover:text-white">
                                                <span property="name">  {{ product_cats }}</span>
                                            </jet-nav-link>
                                            <meta property="position" :content="getCount(full_url)">
                                        </div>
                                    </li>
                                    <li aria-current="page">
                                        <div class="flex items-center">
                                            <svg class="w-auto h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                                <path fill-rule="evenodd"
                                                      d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                                                      clip-rule="evenodd"></path>
                                            </svg>
                                            <span class="ml-1 text-sm font-medium text-gray-500 md:ml-2 dark:text-gray-400 h-5 overflow-hidden break-all">
                                             {{ website.variant.product.name.substring(0, 100) }}<span v-if="website.variant.product.name.length > 100">...</span>
                                            </span>
                                        </div>
                                    </li>
                                </ol>
                            </nav>

                            <div class="clear-both"></div>
                            <h1 class="text-3xl font-bold tracking-tight text-gray-900 float-left max-w-5xl mt-2 dark:text-white" itemprop="name">
                                {{ website.variant.product.name ?? '' }}
                            </h1>

                            <div
                                class="grid w-full grid-cols-1 items-start gap-y-8 gap-x-6 sm:grid-cols-12 lg:gap-x-8 pt-4">
                                <div class="sm:col-span-4 lg:col-span-5" v-if="website.variant.first_picture!==undefined && website.variant.first_picture!==null">
                                    <div @click.passive="goToShop(website.variant.prices_ordered[0])"
                                         class="aspect-w-2 aspect-h-2 dark:bg-white  overflow-hidden sm:col-span-4 lg:col-span-5 rounded-lg border">
                                        <div class="bg-no-repeat bg-center  content-center bg-contain
    h-full w-full object-cover object-center lg:h-full lg:w-full
    " :style="'background-image: url('+website.variant.first_picture.PictureUrlFull" itemprop="image">
                                            <link itemprop="image" :href="website.variant.first_picture.PictureUrlFull"/>

                                        </div>
                                    </div>
                                </div>
                                <div class="col-span-1 sm:col-span-8 lg:col-span-7">
                                    <b class="text-2xl">{{ currency(website.variant.prices_ordered[0].price_discount / 100) }}</b>
                                    <br/>
                                    <a href="#prices" class='!no-underline'
                                       v-if="Object.keys(website.variant.prices_ordered).length ===1">
                                        Beste prijs bij
                                        {{ website.variant.prices_ordered[0].webshop.name }}
                                    </a>
                                    <a href="#prices" class='!no-underline' v-else>
                                        {{ Object.keys(website.variant.prices_ordered).length }}
                                        prijzen, beste prijs bij
                                        {{ website.variant.prices_ordered[0].webshop.name }}<br/>
                                        <span class=' text-sm border'
                                              v-if="website.variant.prices_ordered[0].price_discount!==website.variant.prices_ordered[0].price_total && website.variant.prices_ordered[0].price_total ">
                                             Prijs icl. verzending is: {{ currency(website.variant.prices_ordered[0].price_total / 100) }}
                                        </span>
                                    </a>
                                    <div class="pt-2 pb-2">
                                        <div class="flex flex-col">
                                            <div
                                                @click.passive="goToShop(website.variant.prices_ordered[0])"
                                                class="text-white cursor-pointer right-2 bg-green-600 focus:ring-5
                            focus:outline-none hover:bg-indigo-700 focus:ring-blue-300 font-medium
                            rounded-md text-sm px-4 py-4 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:bg-ind">
                                                Bestel op
                                                {{ website.variant.prices_ordered[0].webshop.name }}
                                            </div>

                                            <div class="  cursor-pointer p-3 pl-0 text-blue-900"  v-on:click="addFavourite(website.variant.id)">
                                                <CustomSvg name="favourite-true"  customClass="fill-blue-800 mt-1.5" v-if="isFavourite(website.variant.id)"></CustomSvg>
                                                <CustomSvg name="favourite-false"  customClass="fill-blue-800 mt-1.5" v-if="!isFavourite(website.variant.id)"></CustomSvg>
                                                <div class="pl-6" v-if="isFavourite(website.variant.id)">Dit product vergeten</div>
                                                <div class="pl-6" v-if="!isFavourite(website.variant.id)">Bewaar als favoriet</div>
                                            </div>

                                        </div>
                                        <ul class='text-sm pt-4'>
                                            <li v-for="(WebshopSentence,key) in website.variant.prices_ordered[0].WebshopSentences ?? []">
                                                <template v-if="WebshopSentence!==''  && key!=='price_total' ">
                                                    <CustomSvg :name="'chevron-check'" color="blue" customClass="w-4 mt-0.5 float-left mr-2 fill-green-600 dark:fill-green-100"
                                                               v-if="WebshopSentence!=='' && key!=='price_total' "></CustomSvg>
                                                    <span v-html="WebshopSentence" v-if="key!=='price_total' "></span>
                                                </template>
                                            </li>
                                        </ul>
                                    </div>

                                    <section aria-labelledby="options-heading" class="mt-1 text-sm">
                                        <h3 id="options-heading" class="sr-only">
                                            Productgegevens</h3>
                                        <div
                                            v-for="(category, group) in website.variant.categories">
                                            <div v-if="Object.keys(category).length > 0 && (group!=='shop')">
                                                <h4 class="pt-1 mt-1 text-sm font-medium text-gray-900 dark:text-gray-200">{{ website.filter_names[group] ?? '' }}</h4>

                                                <span v-if="(group==='color')" v-for="cat in category" class="mt-2 font-bold float-left">
                                                    <jet-nav-link
                                                        :href="('/'+cat.full_url)"
                                                        class="!no-underline float-left">
                                                       <div v-if="cat.url!=='diverse-kleuren'"
                                                            :style="'background-color:'+ cat.code+';  box-shadow: inset 0 2px 5px rgba(0,0,0,0.25)'"
                                                            class="w-auto text-white pl-2 pr-3 pt-0.5 h-6 mt-1 mr-2 float-left  rounded"
                                                            :title="cat.name" itemprop="color">&nbsp;{{ cat.name }}</div>
                                                     <div v-if="cat.full_url==='diverse-kleuren'"
                                                          class="w-6 h-6 mt-1 mr-2 float-left rounded rainbow-product-page"
                                                          :title="cat.name" itemprop="color">&nbsp;{{ cat.name }}</div>
                                                    </jet-nav-link>

                                                </span>
                                                <div class="clear-both"></div>
                                                <span v-if="(group==='brand')" v-for="cat in category" class="mt-2 font-bold ">
                                                        <jet-nav-link :href="('/'+cat.full_url)" class="!no-underline dark:text-white" itemprop="brand"
                                                                      itemtype="https://schema.org/Brand" itemscope>
                                                            <span itemprop="name" :content="cat.name">{{ cat.name }}</span>
                                                        </jet-nav-link>&nbsp;
                                                </span>

                                                <span v-if="(group==='material')" v-for="cat in category" class="mt-2 font-bold">
                                                        <jet-nav-link :href="('/'+cat.full_url)" class="!no-underline dark:text-white">
                                                            <span itemprop="material">{{ cat.name }}</span>
                                                        </jet-nav-link>&nbsp;
                                                </span>
                                                <span v-if="(group==='weight')" v-for="cat in category" class="mt-2 font-bold">
                                                    <span itemprop="material">{{ cat.name }}</span>
                                                </span>
                                                <span v-if="(group==='categories')" v-for="cat in category" class="mt-2 font-bold">
                                                        <jet-nav-link :href="('/'+cat.full_url)" class="!no-underline dark:text-white">
                                                            <span itemprop="category">{{ cat.name }}</span>
                                                        </jet-nav-link>&nbsp;
                                                </span>
                                                <span v-if="(group==='size')" v-for="cat in category" class="mt-2 font-bold">
                                                    <jet-nav-link :href="('/'+cat.full_url)" class="!no-underline dark:text-white">
                                                        <span itemprop="size">{{ cat.name }}</span>
                                                    </jet-nav-link>&nbsp;
                                                </span>

                                            </div>

                                        </div>
                                        <template v-if="website.variant.ean.length > 7">
                                            <h4 class="pt-1 mt-1 text-sm font-medium text-gray-900 dark:text-gray-200">EAN</h4>
                                            <span class="mt-2 font-bold" :itemprop="'gtin'+website.variant.ean.length">{{ website.variant.ean }}</span>
                                        </template>
                                    </section>

                                </div>
                                <div class="col-span-1 sm:col-span-12 lg:col-span-12 h-20 overflow-hidden max-w-4xl"
                                     v-if="!readAll && website.variant.descriptions && website.variant.descriptions[0]">
                                    <div v-html="website.variant.descriptions[0].text" id="infoBox" ref="infoBox" itemprop="description"></div>
                                </div>
                                <div class=" col-span-1sm:col-span-12 lg:col-span-12 overflow-hidden  max-w-4xl"
                                     @click.passive="readAll=false" v-if="readAll && website.variant.descriptions && website.variant.descriptions[0]" v-html="website.variant.descriptions[0].text">
                                </div>
                                <div class="col-span-1 sm:col-span-12 lg:col-span-12 h-10 cursor-pointer underline"
                                     v-if="!readAll && infoHeight > 80" @click.passive="readAll=true">
                                    <button class="text-blue-900 cursor-pointer right-2 bottom-1 bg-white border-blue-900 border border-2
                focus:ring-5 focus:outline-none hover:bg-blue-200 focus:ring-blue-300 font-medium rounded-md text-sm px-4 py-2
                 ">

                                        <CustomSvg :name="'chevron-down'" color="blue" customClass="w-4 mt-0.5 float-left mr-2 !fill-blue-600 "></CustomSvg>

                                        Lees meer
                                    </button>
                                </div>
                                <div class="col-span-1 sm:col-span-12 lg:col-span-12 h-10 cursor-pointer underline"
                                     v-if="readAll" @click.passive="readAll=false">
                                    <button class="
                text-blue-900 cursor-pointer right-2 bottom-1 bg-white border-blue-900 border border-2
                focus:ring-5 focus:outline-none hover:bg-blue-200 focus:ring-blue-300 font-medium rounded-md text-sm px-4 py-2
                dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:bg-indigo-700 dark:text-white">
                                        <CustomSvg :name="'chevron-up'" color="blue" customClass="w-4 mt-0.5 float-left mr-2 fill-blue-600 "></CustomSvg>
                                        Lees minder
                                    </button>
                                </div>
                            </div>
                            <div class="w-full clear-both" id="prices">
                                <h3 class="text-1xl font-bold text-gray-900 sm:pr-12 pb-2 dark:text-white">
                                    Prijzen</h3>
                            </div>
                            <div class="w-full clear-both mb-10 " id="priceBox" ref="priceBox" itemprop="offers" itemtype="https://schema.org/AggregateOffer" itemscope>
                                <meta itemprop="priceCurrency" content="EUR"/>
                                <span itemprop="offerCount">{{ website.variant.prices_ordered.length }}</span> prijzen van
                                <span itemprop="lowPrice"  :content="priceFrom() / 100">{{ currency(priceFrom() / 100) }}</span> tot
                                <span itemprop="highPrice"  :content="priceTo() / 100">{{ currency(priceTo() / 100) }}</span>
                                <div v-for="price in website.variant.prices_ordered" class="cursor-pointer"
                                     @click.passive="goToShop(price)" itemprop="offers" itemtype="https://schema.org/Offer" itemscope>

                                    <div class="flex items-center justify-between  flex-wrap  border-t pt-5 bp-5 pb-5">
                                        <div class="flex-none w-full sm:w-1/5 md:w-1/5  float-left dark:bg-white p-2 mb-2 rounded" property="offers" typeof="Offer">
<!--                                            <a property="url" :href="route('search.redirect', [price.price_id, price.variant_id])"></a>-->
                                            <meta itemprop="availability" content="https://schema.org/InStock"/>
                                            <meta itemprop="priceCurrency" content="EUR"/>
                                            <meta itemprop="itemCondition" content="https://schema.org/NewCondition"/>
                                            <meta itemprop="price" :content="price.price_discount / 100"/>
                                            <meta itemprop="priceValidUntil" :content="priceValidUntil()"/>

                                            <img
                                                :src="'https://cdn.bewinkel.com/155x40a/'+price.webshop.logo"
                                                :alt="price.webshop.name"
                                                class="h-10 w-auto mt-1 mb-2"/>
                                        </div>
                                        <div class="w-2/3 sm:w-3/5  md:w-2/5 sm:pl-4">

                                            <ul class='text-sm'>
                                                <li v-for="(WebshopSentence,key) in price.WebshopSentences ?? []">
                                                 <template  v-if="WebshopSentence!==''  && key!=='price_total' ">
                                                    <CustomSvg :name="'chevron-check'" color="blue" customClass="w-4 mt-0.5 float-left
                                                    mr-2 fill-green-600 dark:fill-green-100" v-if="WebshopSentence!=='' && key!=='price_total'">
                                                    </CustomSvg>
                                                    <span v-html="WebshopSentence" v-if="key!=='price_total' "></span>
                                                 </template>
                                                </li>
                                            </ul>
                                            <ul class='text-sm -ml-2'>
                                                <template v-for="paymentmethod in price.webshop.paymentmethods ?? []">
                                                    <img :src="'https://cdn.bewinkel.com/50x50a/'+paymentmethod.picture" class="h-7 float-left m-2"
                                                         :title="'Betalen met '+paymentmethod.name"/>
                                                </template>
                                            </ul>
                                        </div>
                                        <div class="flex-wrap max-w-2xl p-2 w-1/3 sm:w-1/5 md:w-2/5 text-right">

                                                 <span class='font-bold'>
                                                     <span v-if="price.price_normal!==price.price_discount">
                                                         <span class="line-through text-gray-600">{{ currency(price.price_normal / 100) }} </span>
                                                     </span>
                                                     {{ currency(price.price_discount / 100) }}
                                                 </span>&nbsp;
                                            <span
                                                class="ml-4 text-white cursor-pointer right-2 bottom-1 bg-blue-900 focus:ring-5
                                                     focus:outline-none hover:bg-indigo-700 focus:ring-blue-300 font-medium rounded-md
                                                      text-sm px-4 py-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:bg-indigo-700"
                                                :title="'Bestel op '+price.webshop.name">Bestel</span>

                                            <p class="text-sm pt-3" v-if="price.price_total!=price.price_discount && price.price_total">
                                                Prijs icl. verzending is: {{ currency(price.price_total / 100) }}
                                            </p>
                                            <div class="clear-both"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="mb-10 max-w-4xl" v-if="website.variant.descriptions_sorted.length > 0">
                                <h4 class="text-gray-500 mb-2 dark:text-white">Wat andere zeggen over {{ website.variant.product.name ?? '' }}:</h4>
                                <div class="mb-5 text-gray-600 text-sm" v-for="descriptions in website.variant.descriptions_sorted">
                                    <div class="dark:text-gray-300" v-html="descriptions.text"></div>
                                    <i class="dark:text-gray-400">{{ descriptions.webshop }}</i>

                                </div>
                            </div>

                        </div>

                    </div>
                </main>

            </div>
        </div>
    </section>
    <Footer  :menu_items="menu_items" :page="page" :website="website">
        <template #content_website_footer_left>
            <slot name="content_website_footer_left">
            </slot>
        </template>
        <template #content_website_footer_center>
            <slot name="content_website_footer_center">
            </slot>
        </template>
        <template #content_website_footer_right>
            <slot name="content_website_footer_right">
            </slot>
        </template>
    </Footer>
</template>


<script>

import Header from '@/Pages/Admin/Page/Templates/Partials/Header.vue';
import Footer from '@/Pages/Admin/Page/Templates/Partials/Footer.vue';
import JetNavLink from '@/Jetstream/NavLink.vue'
import {Head} from '@inertiajs/vue3'

import CustomSvg from '@/Pages/Admin/Page/Templates/Partials/Svg.vue';
import axios from "axios";

export default {

    components: {
        Header,
        Footer,
        JetNavLink,
        Head,
        CustomSvg
    },
    props: {
        menu_items: Object,
        page: Object,
        editable: Boolean,
        products: Object,
        website: Object,
    },

    data() {
        return {
            readAll: false,
            infoHeight: Number,
            is_favourite:false,
        }
    },
    methods: {
        itemsContains(n) {
            return this.page.page_options.indexOf(n) > -1
        },
        backExists() {
            if( window && typeof window !== 'undefined') {
                return window.history.length > 1;
            }
            return false;
        },
        back() {
            if( window && typeof window !== 'undefined') {
                window.history.back();
            }
        },
        goToShop(prices_ordered) {
            if (this.website.matomo_id !== '' && typeof _paq !== 'undefined') {
                _paq.push(['trackEvent', 'product', 'Ga naar adverteerder']);
            }

            window.open(route('search.redirect', [prices_ordered.price_id, prices_ordered.variant_id]));
        },
        matchHeight() {

            this.infoHeight = (this.$refs.infoBox.clientHeight);

        },
        priceFrom() {
            const value = o => o.price_total !== null ? o.price_discount : o.price_total;
            let res = this.website.variant.prices_ordered.sort((a, b) => value(a) - value(b));
            res = res[0]
            if (res.price_total === null) {
                return res.price_discount;
            }
            return res.price_total;
        },
        priceTo() {
            const value = o => o.price_total !== null ? o.price_discount : o.price_total;
            let res = this.website.variant.prices_ordered.sort((a, b) => value(a) - value(b));
            res = res[res.length - 1]
            if (res.price_total === null) {
                return res.price_discount;
            }
            return res.price_total;
        },
        priceValidUntil() {
            var currentDate = new Date(new Date().getTime() + 24 * 60 * 60 * 3000);
            var day = currentDate.getDate()
            var month = currentDate.getMonth() + 1
            var year = currentDate.getFullYear()
            return year + "-" + month + "-" + day;
        },
        setDarkClass() {
            //disable
            /*bg-green-500 Dit is een fix!! */
           // const darkTheme = window.matchMedia("(prefers-color-scheme: dark)");
           // if (darkTheme.matches) {
           //     this.$refs.priceBox.classList.add("dark");
            //} else {
                this.$refs.priceBox.classList.remove("dark");
            //}
        },
        getCount(full_url) {
            var i = 0;
            var arr = Object.keys(this.website.variant.bread_crumbs);
            var count = 0;
            arr.forEach((item, index) => {
                i++;
                if (item == full_url) {
                    count = i;
                }
            });
            return count;
        }
    },
    mounted() {
        //show / hight alleen tonen als echt nodig
        this.setDarkClass();//priceBox
        this.matchHeight();//infoBox
    },
}

</script>

